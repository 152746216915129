import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import CreateTimeCapsule from "./pages/CreateTimeCapsule";
import TimeCapsuleDetail from "./pages/TimeCapsuleDetail";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/create" element={<CreateTimeCapsule />} />
        <Route path="/capsule/:id" element={<TimeCapsuleDetail />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the import path as needed
import { TextField, Button, Typography, Container, Box } from "@mui/material";

const CreateTimeCapsule = () => {
  const [name, setName] = useState("");
  const [emails, setEmails] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState(""); // New state for description
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const users = emails.split(",").map((email) => email.trim());

    // Convert the entered date to noon UTC
    const enteredDate = new Date(date);
    const openDate = new Date(
      Date.UTC(
        enteredDate.getUTCFullYear(),
        enteredDate.getUTCMonth(),
        enteredDate.getUTCDate(),
        12,
        0,
        0
      )
    );

    try {
      await addDoc(collection(db, "timecapsules"), {
        name,
        users,
        date: Timestamp.fromDate(openDate), // Save date as midnight UTC
        description, // Save description to Firestore
        contents: [],
      });

      navigate("/");
    } catch (error) {
      console.error("Error creating capsule:", error);
    }
  };

  return (
    <Container maxWidth="sm" className="email-request">
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create Memory Capsule
        </Typography>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Emails"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          margin="normal"
          required
          helperText="User emails should be comma-separated. Be sure to include your own."
        />
        <TextField
          fullWidth
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          margin="normal"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          margin="normal"
          multiline
          rows={4}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Create Capsule
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mt: 2, ml: 2 }}
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
};

export default CreateTimeCapsule;

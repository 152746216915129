// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAZ7OOfeSXzFS246OizE633aBaxpDID6z0",
  authDomain: "timecapsule-e0053.firebaseapp.com",
  projectId: "timecapsule-e0053",
  storageBucket: "timecapsule-e0053.appspot.com",
  messagingSenderId: "539328973756",
  appId: "1:539328973756:web:82191edd4b86d94f2d39b9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Input,
} from "@mui/material";
import { db, storage } from "../firebase"; // Adjust the import path as needed
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TimeCapsuleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [capsule, setCapsule] = useState(null);
  const [newContent, setNewContent] = useState("");
  const [newMedia, setNewMedia] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const fetchCapsule = async () => {
      try {
        const capsuleDoc = await getDoc(doc(db, "timecapsules", id));
        if (capsuleDoc.exists()) {
          const capsuleData = capsuleDoc.data();
          setCapsule(capsuleData);

          // Check if the capsule is open
          const currentDate = new Date();
          const openDate = capsuleData.date.toDate();
          setIsOpen(currentDate >= openDate);

          // Convert the open date to Eastern Time
          const formattedOpenDate = openDate.toLocaleString("en-US", {
            timeZone: "America/New_York",
          });
          setCapsule((prevCapsule) => ({
            ...prevCapsule,
            formattedOpenDate,
          }));

          // Set countdown timer
          if (currentDate < openDate) {
            const interval = setInterval(() => {
              const now = new Date();
              const timeLeft = openDate - now;
              const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
              const hours = Math.floor(
                (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              const minutes = Math.floor(
                (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
              );
              const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
              setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);

              if (timeLeft < 0) {
                clearInterval(interval);
                setCountdown("The capsule is now open!");
                setIsOpen(true);
              }
            }, 1000);
            return () => clearInterval(interval);
          }
        }
      } catch (error) {
        console.error("Error fetching capsule:", error);
      }
    };

    fetchCapsule();
  }, [id]);

  const handleAddContent = async () => {
    try {
      const entryTime = new Date();

      const newEntry = {
        text: newContent,
        entryTime: entryTime,
      };

      if (newMedia) {
        const mediaRef = ref(storage, `timecapsules/${id}/${newMedia.name}`);
        await uploadBytes(mediaRef, newMedia);
        const mediaURL = await getDownloadURL(mediaRef);
        newEntry.mediaType = newMedia.type;
        newEntry.mediaUrl = mediaURL;
        setNewMedia(null);
      }

      await updateDoc(doc(db, "timecapsules", id), {
        contents: arrayUnion(newEntry),
      });

      setNewContent("");

      // Refresh the capsule data
      const capsuleDoc = await getDoc(doc(db, "timecapsules", id));
      if (capsuleDoc.exists()) {
        setCapsule(capsuleDoc.data());
      }

      // Show success toast message
      toast.success("Content added successfully!");
    } catch (error) {
      console.error("Error adding content:", error);
      toast.error("Failed to add content.");
    }
  };

  if (!capsule) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <Typography variant="h4" component="h1" gutterBottom>
        {capsule.name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {isOpen ? "Opened" : "Opens"} on: {capsule.formattedOpenDate}
      </Typography>
      {!isOpen && (
        <Typography variant="body1" gutterBottom>
          Countdown: {countdown}
        </Typography>
      )}
      <Typography variant="subtitle1">
        Users: {capsule.users.join(", ")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Description: {capsule.description}
      </Typography>
      {isOpen ? (
        <>
          <Typography variant="body1" gutterBottom>
            Contents:
          </Typography>
          <List>
            {capsule.contents.map((content, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      {content.mediaType &&
                        (content.mediaType.startsWith("image/") ? (
                          <img
                            src={content.mediaUrl}
                            alt="media"
                            style={{ maxWidth: "100%", marginRight: "10px" }}
                          />
                        ) : (
                          <video
                            src={content.mediaUrl}
                            controls
                            style={{ maxWidth: "100%", marginRight: "10px" }}
                          />
                        ))}
                      {content.text}
                    </>
                  }
                  secondary={`Added on: ${new Date(
                    content.entryTime?.seconds * 1000
                  ).toLocaleString("en-US", { timeZone: "America/New_York" })}`}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          The contents of this memory capsule will be revealed on{" "}
          {capsule.date.toDate().toLocaleDateString()}.
        </Typography>
      )}
      <Container class="email-request">
        <TextField
          label="New Content"
          fullWidth
          margin="normal"
          value={newContent}
          onChange={(e) => setNewContent(e.target.value)}
        />
        <Input
          type="file"
          inputProps={{ accept: "image/*,video/*" }}
          onChange={(e) => setNewMedia(e.target.files[0])}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddContent}
          style={{ marginRight: "10px" }}
        >
          Add Content
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </Container>
    </Container>
  );
};

export default TimeCapsuleDetail;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const functions = getFunctions();
  const [loadingSendCapsuleInfo, setLoadingSendCapsuleInfo] = useState(false);

  const handleCreateNewCapsule = () => {
    navigate("/create");
  };

  const handleRequestInfo = async () => {
    setLoadingSendCapsuleInfo(true);
    const sendCapsuleInfo = httpsCallable(functions, "sendCapsuleInfo");
    try {
      let sendCapsuleInfoResult = await sendCapsuleInfo({ email });
      if (sendCapsuleInfoResult.data.success) {
        toast.success("Email sent!");
        setEmail("");
      } else {
        toast.error(
          "Could not find any capsules for that email. If you would like to create a new capsule, click Create New Capsule."
        );
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email. Please try again.");
    }
    setLoadingSendCapsuleInfo(false);
  };

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Memory Capsules
      </Typography>

      <p>
        A Memory Capsule functions like a digital time capsule. Use Memory
        Capsules to track memories, predictions, or other fun things with your
        friends. I was inspired to make this site because I wanted to reconnect
        with friends who I haven't seen in a while, and an email full of
        memories from our time together is a perfect way to spark conversation
        with old friends. This is a loginless app, you you can click Create New
        Capsule to setup a new capsule, or if you're already part of a memory
        capsule, find the link to it in your email or click Request Capsule Info
        to get an email with links to all the capsules you're part of.
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateNewCapsule}
        style={{ marginBottom: "20px" }}
      >
        Create New Capsule
      </Button>
      <ToastContainer />
      <Container class="email-request">
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          disabled={loadingSendCapsuleInfo}
          required
        />
        <Button
          className="button"
          variant="contained"
          color="primary"
          onClick={handleRequestInfo}
          disabled={loadingSendCapsuleInfo}
        >
          {loadingSendCapsuleInfo ? (
            <CircularProgress size={24} />
          ) : (
            "Request Info"
          )}
        </Button>
        /
      </Container>
    </Container>
  );
};

export default LandingPage;
